.root-providers {
  height: 100%;
  background-image: url("../images/providers-wallpaper.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
body {
  background-color: #e4e4e4 !important;
  padding: 1rem;
}
.text-muted {
  color: #212529 !important;
  text-align: center;
}
.MuiAvatar-img {
  width: auto !important;
}

.hospital {
  width: 500px;
  height: 350px;
}

.icon-login-container {
  width: 100px;
  display: flex;
  justify-content: center;
}
.icon-login-avatar {
  background-color: transparent;
  width: 2000px !important;
  height: 150px !important;
  border-radius: 0;
  margin-bottom: 30px;
  padding: 10px;
}

@media only screen and (max-width: 450px) {
  /* For mobile phones: */
  .hospital {
    width: 300px;
    height: 220px;
    padding-top: 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* For ipad/tablets phones: */
  .hospital {
    width: 550px;
    height: 450px;
    padding-top: 50px;
  }
}
