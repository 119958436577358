.maincontainer {
  padding: 0rem 3rem 0rem 3rem;
}

.button {
  padding: 10px 45px 30px 0px;
}

@media only screen and (max-width: 450px) {
  /* For mobile phones: */
  .button {
    padding: 15px 0px 15px 0px;
  }
  .maincontainer {
    padding: 0px;
    width: 360px;
  }
  .fc {
    width: 360px;
    height: 600px;
    padding-top: 20px;
  }
  .fc .fc-toolbar {
    justify-content: space-around;
    padding-bottom: 20px;
  }
  .fc-header-toolbar {
    height: 100px;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .fc-button-group {
    padding: 10px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* For ipad/tablets phones: */
  .fc {
    width: 650px;
    height: 700px;
    padding-top: 50px;
  }
}
