@import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400;500;600;700;800&family=Open+Sans&display=swap');

@import-normalize;

.home-logo {
    padding: 7% 0;
    width: 30%;
  }
 
  #date {
    float:left;
    margin-left:-350px;
  }
 