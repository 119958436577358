.hospital {
  width: 500px;
  height: 350px;
}

@media only screen and (max-width: 450px) {
  /* For mobile phones: */
  .hospital {
    width: 300px;
    height: 220px;
    padding-top: 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* For ipad/tablets phones: */
  .hospital {
    width: 550px;
    height: 450px;
    padding-top: 50px;
  }
}

.lang-buttons-container {
  align-items: center;
}

.lang-text {
  color: black;
}
