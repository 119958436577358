.btn-toggle-login {
  background-color: #00b4d885;
}

.btn-toggle-login:hover {
  background-color: #0077b6;
  color: aliceblue;
}

.btn-toggle-register {
  background-color: #a8dadc;
}
.btn-toggle-register:hover {
  background-color: #0077b6;
  color: aliceblue;
}
