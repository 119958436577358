.img-cropper {
  width: 100px;
  height: 100px;
  margin: auto;
  overflow: hidden;
  border-radius: 50%;
}
.img-d-cropper {
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
}
.profile-avatar {
  height: 100%;
}
.buttonDelete {
  color: #f22828 !important;
  /* background-color: #f22828 !important; */
}

/* a:not([href]):hover {
  text-decoration: inherit;
} */
.img-cropper-profile {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 60px;
  height: 60px;
  margin: auto;
  overflow: hidden;
  border-radius: 50%;
}

.label-avatar {
  color: midnightblue;
  font-weight: bold;
}

.label-form {
  color: lightslategray;
}

.MuiList-root > a:hover {
  text-decoration: none;
}
.container-card-role {
  height: 70vh;
}

.container-card-inner-role {
  min-height: 50vh;
}

.MuiDrawer-paper {
  background-color: #212529 !important;
}
/* 
.MuiDrawer-paper.MuiPaper-root,
.MuiListItemIcon-root {
  color: antiquewhite !important;
} */
.MuiList-root > a,
.MuiListItemIcon-root {
  color: #d9eef7 !important;
  /* text-decoration: none; */
}

.makeStyles-paper-12 {
  height: 450px !important;
}

.MuiCardHeader-content > .MuiTypography-h5 {
  font-size: 0.9rem;
}

.MuiDivider-root {
  background-color: #343a40 !important;
}

.h1-section-title {
  font-size: 2rem;
  color: #596067;
}

.span-section-title {
  font-size: 1rem;
}

.title-app-review {
  color: lightslategray;
}

.image-profile {
  top: -4px !important;
}
.border-green {
  border: 1px solid #28a745 !important;
}

.w-40-custom {
  width: 40% !important;
}

.w-30-custom {
  width: 30% !important;
}

.w-20-custom {
  width: 20% !important;
}

.w-15-custom {
  width: 15% !important;
}

.w-10-custom {
  width: 10%;
}
.title-activate-user {
  font-weight: bold !important;
  color: grey;
}

.fieldset-status {
  padding: 10px;
  box-shadow: 2px 2px 11px 0px lightgrey;
  border-radius: 5px;
}

/* .makeStyles-drawerClose-24 {
    width: 48px !important;
}


.makeStyles-drawerClose-25 {
    width: 48px !important;
} */

.divider {
  height: auto;
  max-height: 200px;
  width: 8px;
  background-color: #1089e299; /* #dcddde73*/
  margin: 5px;
  border-radius: 8px;
  border: none;
}

.divider-main {
  height: 160px;
  max-height: 160px;
  width: 4px;
  background-color: #1089e299; /* #dcddde73*/
  margin: 5px;
  border-radius: 8px;
  border: none;
}

.dividerAppbar {
  height: 40px;
  max-height: 40px;
  width: 2px;
  background-color: #0c436a9e; /* #dcddde73*/
  margin: 10px;
  margin-right: 15px;
  border-radius: 8px;
  border: none;
}
/* .menuAppbar {
  color: white !important;
} */

.rounded-lg-custom {
  border-radius: 1.3rem !important;
}

.rounded-sm-custom {
  border-radius: 0.3rem !important;
}

.rounded-md-custom {
  border-radius: 1rem !important;
}

.sunshine-icon {
  width: 5%;
  margin-bottom: 5px;
}
.avatar-custom {
  border: 2px solid #085279a1;
  background-color: black;
  width: "50px";
  height: "50px";
  align-items: center !important;
}

.avatar-rate {
  border: 2px solid #085279a1;
  background-color: black;
  width: "100px !important";
  height: "100px !important";
}

.search-panel {
  background-color: #e3e9ec !important;
}

.appt-card {
  max-width: auto;
}

.font-appt-date {
  font-weight: 900;
  font-size: large;
}

.sub-container-widget {
  height: 500px;
  /* border: 1px solid blue; */
}

.background-root {
  background-color: #e5e5e5;
}

.makeStyles-root-4 {
  background-color: #e5e5e5;
}
/* .experience {
  font-size: 20px;
} */

.DivWithScroll {
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}
.box-content {
  height: 200px;
  width: 250px;
  overflow: auto;
  border-radius: 8px;
}
.text-label {
  font-size: medium;
  font-weight: bold;
}
.text-span-value {
  font-size: medium;
  font-weight: bold;
  color: #085279;
}
.container-appt-summary {
  margin: 10px;
  padding: 10px;
}

.divider-dashboard-appt {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    #a3c0de,
    rgba(0, 0, 0, 0)
  );
}

.title-dashboard-card {
  font-size: 20px;
  font-weight: bold;
  color: #a9bdc3;
}
.title-custome {
  font-size: 20px;
  font-weight: bold;
  color: #afafaf;
}
.flag-size {
  width: 30px;
}

.issued {
  border: 1px solid rgb(209, 209, 209);
  border-radius: 5px;
  width: 100%;
  height: 100%;
}
.selectOverflow {
  overflow-x: scroll !important;
}

.checkout-resume-table {
  border: 2px solid #e0eaf5;
  margin: 10px;
  border-radius: 8px;
}

/* .prescription-card {
  width: 700px;
  height: 400px;
} */

.prescription-card:not(.shadow.p-3.m-2.bg-white.rounded-lg.col-md-12.col-sm-12.position-relative.d-flex.flex-column.justify-content-between) {
  width: 700px;
  height: 200px;
}

.prescription-card.shadow.p-3.m-2.bg-white.rounded-lg.col-md-12.col-sm-12.position-relative.d-flex.flex-column.justify-content-between {
  width: 700px;
  height: 400px;
}

.dependants-card {
  /* width: 700px; */
  /* height: 260px; */
  width: auto;
  height: auto;
  max-height: 260px;
}

.h-60-custom {
  height: 60px !important;
}

.h-80-custom {
  height: 80px !important;
}
.fc .fc-highlight {
  background: #55ad27a6 !important;
}

.text-instructions {
  font-size: 15px;
  font-family: system-ui;
  color: #294ea2;
  font-weight: 500;
}

.paymentIcon-completed {
  font-size: 20px;
  color: green;
  margin-left: 5px;
}

.paymentIcon-await {
  font-size: 20px;
  color: rgb(42, 54, 68);
  margin-left: 5px;
}

.box-hour {
  width: 100px;
  border-radius: 8px;
  border: 2px solid var(--blue);
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  margin: 2px;
}

.box-hour:hover {
  background-color: var(--blue);
  color: white;
  transition: box-shadow 0.3s ease 0s;
}

.box-hour-selected {
  background-color: var(--blue);
  color: white;
}

.fieldset {
  position: relative;
  border: 1px solid #ddd;
  padding: 10px;
}

.fieldset h3 {
  position: absolute;
  top: 0;
  font-size: 14px;
  font-weight: bold;
  color: #085279;
  line-height: 1;
  margin: -9px 0 0; /* half of font-size */
  background: whitesmoke;
  padding: 0 3px;
}

.timeline-message-container {
  overflow-y: scroll;
  height: 40%;
}

.label-read-btn {
  font-size: 15px;
  font-weight: bolder;
  margin-right: 5px;
  color: #085279;
}

.video-container {
  width: 70% !important;
}

.input-condition-detail {
  border-radius: 8px;
  border-color: lightgray;
}

.textResponseOnSave {
  text-align: center;
  font-size: 18px;
  margin: 8px;
  color: brown !important;
}

.fadeout {
  color: #f5efef;
  font-size: large;
  font-family: helvetica;
  text-align: center;
  opacity: 0;
  -webkit-animation: fadeout 4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 4s; /* Firefox < 16 */
  -ms-animation: fadeout 4s; /* Internet Explorer */
  -o-animation: fadeout 4s; /* Opera < 12.1 */
  animation: fadeout 4s;
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.timer-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 0 1 25%;
  -ms-flex: 0 1 25%;
  flex: 0 1 25%;
  font-size: 20px;
  background-color: #404549;
  border-radius: 5px;
  padding: 5px 10px;
  color: white;
}

.dots-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  margin: 4px 2px 10px 2px;
}

.dots-timer {
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #404549;
  border-radius: 6px;
  margin: 5px 0px;
}

.timer-parent-container {
  width: 40px;
  max-width: 40px;
}

.paypal-buttons {
  max-width: 600px;
}

.specialty-chip {
  color: white;
  background-color: #03a9f4;
}

.btn-login-custom {
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  background-color: #2c7cf3;
  color: #fff;
  font-weight: 500;
}

.bg-gray-custom {
  background-color: #f9fafb !important;
}

.title-admin-form {
  font-size: 2rem;
}

.login-side-ads {
  width: 100%;
  height: 100%;
  background-image: url("https://res.cloudinary.com/brackets-it/image/upload/w_1200,h_800/v1627784016/wallpaper-doctor_p1wsvc.jpg");
  background-repeat: no-repeat;
}

.text-red-900 {
  color: rgb(216, 101, 101);
}

.card-higthlights {
  height: 300px !important;
  max-height: 300px;
}

.avatar-company-custom {
  border: 5px solid #0a8ed4;
  background-color: black;
  width: 150px !important;
  height: 150px !important;
  align-items: center !important;
}

.custom-cancel-button {
  background-color: #e0e0e0;
}

.MuiListSubheader-colorPrimary {
  background-color: #212529 !important;
}

.appt-type-icon {
  display: grid;
  float: left;
}

.appt-indicator-icon {
  color: #21cdbd;
  font-size: 2rem !important;
}
.appt-type-label {
  font-size: 0.5rem;
}

.card-header .header-card-user {
  padding: 0px;
}

.divider-appt-op{
  /* border: 0; */
  height: 1px;
  background-color: #b8bbc5 !important;
  width: 70%;
  
}

.MuiAccordionDetails-root{
  min-height: 280px;
}

.w-vw-100 {
  width: 100vw;
}

.dropzone{
  border: 2px dashed #0087F7;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin: 10px;
}

@media only screen and (max-device-width: 768px) {
  /* For ipad/tablets phones: */
  .login-side-ads {
    display: none !important;
  }
}

.card-doctor-summary {
  font-size: 14px;
  font-weight: bold;
}

/* Order Card */
.card_header_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.chip-card-label {
  font-size: 10px;
  margin-top: 5px;
}

.w-full {
  width: 100%;
}

.mb-10-custom {
  margin-bottom: 10%;
}

.card_description {
  color: lightslategray;
  text-align: justify;
}

.row-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.m-10-custom {
  margin-left: 10px;
}

.fontS-2 {
  font-size: 13px;
}

.mw-160 {
  max-width: 160px !important;
}

[id*="jitsiMeeting-"] {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}


.chat-container {
  background-color: #f5f5f5;
  padding: 20px;
  max-height: 400px;
  overflow-y: auto;
  border-radius: 10px;
  margin: 0 auto;
  width: 50%;
}

.chat-container p {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  display: inline-block;
  max-width: 80%;
  margin-bottom: 10px;
}

.chat-container form {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: white;
  border-top: 1px solid #ccc;
}

.ai-icon {
  color: #ba1ee1;
  font-size: 2rem;
  margin-right: 10px;
}

.legend {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.legend-item {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.color-box {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 3px;
}

.divider-container {
  position: relative;
  text-align: center;
  margin: 15px 0;
}

.divider-line {
  margin: 0;
  border: none;
  border-top: 1px solid #dee2e6;
}

.divider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0 10px;
  color: #6c757d;
  font-weight: bold;
}