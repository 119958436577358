.alertTermsUse {
    background-color: #bcd7f5;
    overflow-y: scroll;
    height: 100px;
}
.cursor {
    cursor: pointer;
}
a:not([href]) {
    text-decoration-line: underline;
    text-decoration: inherit;
    color: #257adb;
    cursor: pointer;
}
p{
    margin: 10px;
}
.swal2-content {
    z-index: 1;
    justify-content: center;
    margin: 0;
    padding: 0;
    color: #545454;
    font-size: 1.125em;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    word-wrap: break-word;
    overflow-y: scroll;
    height: 500px;
}
.swal2-popup {
    display: none;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    width: 36em !important;
    max-width: 100%;
    padding: 1.25em;
    border: none;
    border-radius: .3125em;
    background: #fff;
    font-family: inherit;
    font-size: 1rem;
}
p label{
    text-decoration: inherit;
    color: #257adb;
    cursor: pointer;
}