.hvr-grow {
    display: inline-block;
    vertical-align: middle;
    transform: translateZ(0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
    border-radius: 10px;
    margin: 0.7em;
    box-shadow: 0px 3px 1px #999;
   
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
    transform: scale(1.1);

}
.hvr-grow img{
    width: 180px;
    height: 120px;
}